
import { computed, defineComponent, ref } from 'vue'
import TmModal from '@/components/shared/modal/TmModal.vue'
import ShareTemplatesFilter from '@/components/modals/templates/share/ShareTemplatesFilter.vue'
import ShareTemplatesTable from '@/components/modals/templates/share/ShareTemplatesTable.vue'
import type { TableHeaders } from '@/definitions/shared/types'
import { filterItemsByValues } from '@/services/utils'
import { getTableData } from '@/services/tableService'
import type { SubAccounts } from '@/definitions/account/subAccounts/types'

export default defineComponent({
  name: 'TemplatesShareTemplateModal',
  components: { ShareTemplatesTable, ShareTemplatesFilter, TmModal },
  setup() {
    const search = ref('')
    const selected = ref([])
    const tableHeaders = ref<TableHeaders[]>([
      { text: 'Name', value: 'name-slot' },
      { text: 'Email', value: 'email' },
      { text: 'Role', value: 'role-slot' },
      { text: 'Status', value: 'status-slot' },
    ])
    const tableItems: SubAccounts[] = getTableData('subAccounts')
    const filteredTableItems = computed<SubAccounts[]>(
      () => tableItems.filter(
        (item) => filterItemsByValues(
          search.value,
          [item.name.fullName, item.email, item.role.name, item.status.name]
        )
      )
    )

    return {
      search,
      selected,
      tableHeaders,
      filteredTableItems,
    }
  },
})
