import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_person = _resolveComponent("tm-person")!
  const _component_tm_status = _resolveComponent("tm-status")!
  const _component_tm_table = _resolveComponent("tm-table")!

  return (_openBlock(), _createBlock(_component_tm_table, null, {
    "body-cell-name-slot": _withCtx(({ row }) => [
      _createVNode(_component_tm_person, {
        name: row.name.fullName,
        "avatar-color": row.name.avatarColor,
        "avatar-size": "small"
      }, null, 8, ["name", "avatar-color"])
    ]),
    "body-cell-role-slot": _withCtx(({ row }) => [
      _createVNode(_component_tm_status, _normalizeProps(_guardReactiveProps(row.role)), null, 16)
    ]),
    "body-cell-status-slot": _withCtx(({ row }) => [
      _createVNode(_component_tm_status, _normalizeProps(_guardReactiveProps(row.status)), null, 16)
    ]),
    _: 1
  }))
}