
import { defineComponent } from 'vue'
import TmTable from '@/components/shared/table/TmTable.vue'
import TmStatus from '@/components/shared/TmStatus.vue'
import TmPerson from '@/components/shared/TmPerson.vue'

export default defineComponent({
  name: 'ShareTemplatesTable',
  components: { TmPerson, TmTable, TmStatus },
})
