import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_share_templates_filter = _resolveComponent("share-templates-filter")!
  const _component_share_templates_table = _resolveComponent("share-templates-table")!
  const _component_tm_modal = _resolveComponent("tm-modal")!

  return (_openBlock(), _createBlock(_component_tm_modal, {
    id: "templatesShare",
    size: "xLarge",
    "no-footer": ""
  }, {
    "modal-content": _withCtx(() => [
      _createVNode(_component_share_templates_filter, {
        search: _ctx.search,
        "onUpdate:search": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.search) = $event)),
        selected: _ctx.selected
      }, null, 8, ["search", "selected"]),
      _createVNode(_component_share_templates_table, {
        modelValue: _ctx.selected,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selected) = $event)),
        headers: _ctx.tableHeaders,
        items: _ctx.filteredTableItems,
        "item-key": "id",
        "items-name": "sub-accounts",
        "show-selection": ""
      }, null, 8, ["modelValue", "headers", "items"])
    ]),
    _: 1
  }))
}