
import { defineComponent } from 'vue'
import TopFilter from '@/components/layout/topFilter/TopFilter.vue'
import TmButton from '@/components/shared/TmButton.vue'
import useTemplatesOpenModal from '@/compositions/templates/useTemplatesOpenModal'

export default defineComponent({
  name: 'ShareTemplateFilter',
  components: {
    TmButton,
    TopFilter,
  },
  props: {
    selected: {
      type: Array,
    },
  },
  setup() {
    const {
      openModalShareConfirm,
    } = useTemplatesOpenModal()

    return {
      openModalShareConfirm,
    }
  },
})
